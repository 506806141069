import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TokenFilters from '@components/shared/TokenFilters'
import { expandDecimals } from '@components/trade/rfx/tradingview/lib/numbers'
import cx from 'classnames'
import SearchInput from 'components/SearchInput/SearchInput'
import { CLOUD_FRONT_URL } from 'config/constants'
import { getToken } from 'config/tokens'
import { convertToUsd } from 'domain/synthetics/tokens'
import { InfoTokens, Token, TokenInfo } from 'domain/tokens'
import { BigNumber } from 'ethers'
import { formatAmount } from 'rfx/lib/numbers'
import { useViewport } from 'hooks/useViewport'
import Image from 'next/image'
import DropDownIcon from 'public/icons/DROP_DOWN.svg'
import ArrowDown from 'public/icons/arrow-down.svg'
import CloseIcon from 'public/icons/close-icon-modal.svg'
import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TooltipWithPortal from '../Tooltip/TooltipWithPortal'
import { Popover } from '@headlessui/react'
import useBreakpoints from 'hooks/useBreakpoints'

type TokenState = {
  disabled?: boolean
  message?: string
}

type Props = {
  chainId: number
  label?: string
  className?: string
  tokenAddress: string
  tokens: Token[]
  infoTokens?: InfoTokens
  showMintingCap?: boolean
  mintingCap?: BigNumber
  disabled?: boolean
  selectedTokenLabel?: ReactNode | string
  showBalances?: boolean
  showTokenImgInDropdown?: boolean
  showSymbolImage?: boolean
  showNewCaret?: boolean
  getTokenState?: (info: TokenInfo) => TokenState | undefined
  disableBodyScrollLock?: boolean
  onSelectToken: (token: Token) => void
  extendedSortSequence?: string[] | undefined
  inputFieldValue?: string
}

const tokenFilters: Record<string, { label: string; count: number }> = {
  Available: {
    label: 'Available',
    count: 0,
  },
  All: {
    label: 'All',
    count: 0,
  },
  Crypto: {
    label: 'Crypto',
    count: 0,
  },
  Stables: {
    label: 'Stables',
    count: 0,
  },
}

export default memo(function TokenSelector(props: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  let tokenInfo: TokenInfo | undefined
  const [appliedTokenFilter, setAppliedTokenFilter] = useState(
    tokenFilters['All'].label,
  )
  const { isMobile } = useViewport()
  const { below } = useBreakpoints()

  try {
    tokenInfo = getToken(props.chainId, props.tokenAddress)
  } catch (e) {
    // ...ignore unsupported tokens
  }

  const {
    tokens,
    mintingCap,
    infoTokens,
    showMintingCap,
    disabled,
    selectedTokenLabel,
    showBalances = true,
    showTokenImgInDropdown = false,
    showSymbolImage = false,
    showNewCaret = false,
    getTokenState = () => ({ disabled: false, message: null }),
    extendedSortSequence,
    inputFieldValue,
  } = props

  const visibleTokens = tokens.filter((t) => t && !t.isTempHidden)

  const onSelectToken = (token: Token) => {
    setIsModalVisible(false)
    props.onSelectToken(token)
  }

  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword('')
    }
  }, [isModalVisible])

  const onSearchKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value)
  }

  const filteredTokens = useMemo(() => {
    if (appliedTokenFilter === tokenFilters['Stables'].label) {
      return visibleTokens.filter((item) => item.isStable === true)
    }

    return visibleTokens.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
        item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
      )
    })
  }, [appliedTokenFilter, searchKeyword, visibleTokens])

  const updateTokenFiltersCount = useMemo(() => {
    const updatedFilters = { ...tokenFilters }
    Object.keys(updatedFilters).forEach((key) => {
      switch (key) {
        case 'Available':
          updatedFilters[key].count = visibleTokens?.length || 0
          break
        case 'All':
        case 'Crypto':
          updatedFilters[key].count = visibleTokens?.length || 0
          break
        case 'Stables':
          updatedFilters[key].count =
            visibleTokens.filter((item) => item.isStable === true)?.length || 0
          break

        default:
          break
      }
    })
    return Object.keys(updatedFilters).map((item) => {
      return {
        label: updatedFilters[item].label,
        count: updatedFilters[item].count,
      }
    })
  }, [visibleTokens])

  const sortedFilteredTokens = useMemo(() => {
    const tokensWithBalance: Token[] = []
    const tokensWithoutBalance: Token[] = showBalances ? [] : filteredTokens

    for (const token of filteredTokens) {
      const info = infoTokens?.[token.address]
      if (showBalances) {
        if (info?.balance?.gt(0)) {
          tokensWithBalance.push(token)
        } else {
          tokensWithoutBalance.push(token)
        }
      }
    }

    const sortedTokensWithBalance = tokensWithBalance.sort((a, b) => {
      const aInfo = infoTokens?.[a.address]
      const bInfo = infoTokens?.[b.address]

      if (!aInfo || !bInfo) {
        return 0
      }

      if (
        aInfo?.balance &&
        bInfo?.balance &&
        aInfo?.maxPrice &&
        bInfo?.maxPrice
      ) {
        const aBalanceUsd = convertToUsd(
          aInfo.balance,
          a.decimals,
          aInfo.minPrice,
        )
        const bBalanceUsd = convertToUsd(
          bInfo.balance,
          b.decimals,
          bInfo.minPrice,
        )

        return bBalanceUsd?.sub(aBalanceUsd || 0).gt(0) ? 1 : -1
      }
      return 0
    })

    const sortedTokensWithoutBalance = tokensWithoutBalance.sort((a, b) => {
      const aInfo = infoTokens?.[a.address]
      const bInfo = infoTokens?.[b.address]

      if (!aInfo || !bInfo) {
        return 0
      }

      if (extendedSortSequence) {
        // making sure to use the wrapped address if it exists in the extended sort sequence
        const aAddress =
          aInfo.wrappedAddress &&
          extendedSortSequence.includes(aInfo.wrappedAddress)
            ? aInfo.wrappedAddress
            : aInfo.address

        const bAddress =
          bInfo.wrappedAddress &&
          extendedSortSequence.includes(bInfo.wrappedAddress)
            ? bInfo.wrappedAddress
            : bInfo.address

        return (
          extendedSortSequence.indexOf(aAddress) -
          extendedSortSequence.indexOf(bAddress)
        )
      }

      return 0
    })

    return [...sortedTokensWithBalance, ...sortedTokensWithoutBalance]
  }, [filteredTokens, infoTokens, extendedSortSequence, showBalances])

  const _handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0])
    }
  }

  const tokenFilterChangeHandler = useCallback((val: string) => {
    setAppliedTokenFilter(val)
  }, [])

  if (!tokenInfo) {
    return null
  }

  function displayTokens(close?: () => void) {
    return (
      <>
        <div onClick={() => setIsModalVisible(false)} />
        <div className="absolute left-0 top-0 z-30 flex h-full w-full flex-col rounded-md border border-none border-th-input-border">
          <div className="glassmorphism-bg rounded-md sm:rounded-none" />
          <div className="glassmorphism rounded-md sm:rounded-none" />
          <Image
            alt=""
            className="pointer-events-none absolute rounded-md sm:rounded-none"
            src={CLOUD_FRONT_URL + '/images/bg-noise-swap.png'}
            layout="fill"
          />
          <div className="relative p-2 lg:p-6">
            {!below.lg && (
              <div className="flex flex-col border-b border-th-input-border-secondary pb-4">
                {!isMobile && (
                  <div
                    className="cursor-pointer self-end"
                    onClick={() => setIsModalVisible(false)}
                  >
                    <CloseIcon />
                  </div>
                )}

                <div className="text-base font-bold text-th-fgd-1 sm:text-2xl">
                  {props.label}
                </div>
              </div>
            )}

            <SearchInput
              className="mb-3 mt-4 h-10 !bg-th-bkg-2 text-sm sm:h-11"
              inputBoxClasses="h-11"
              value={searchKeyword}
              setValue={onSearchKeywordChange}
              onKeyDown={_handleKeyDown}
              iconSize={below.lg ? 'small' : 'regular'}
            />

            <TokenFilters
              appliedFilter={appliedTokenFilter}
              changeFilter={tokenFilterChangeHandler}
              options={updateTokenFiltersCount}
              labelClasses="uppercase"
              forModal
            />
          </div>

          <div className="TokenSelector-tokens relative mx-2 h-full flex-1 flex-col overflow-y-auto lg:mx-[1.5rem]">
            {filteredTokens?.length === 0 && (
              <div className="relative flex w-full items-center justify-center text-th-fgd-1">
                No Tokens Available
              </div>
            )}
            {sortedFilteredTokens.map((token, tokenIndex) => {
              let info = infoTokens?.[token.address] || ({} as TokenInfo)

              let mintAmount
              let balance = info.balance
              if (showMintingCap && mintingCap && info.usdgAmount) {
                mintAmount = mintingCap.sub(info.usdgAmount)
              }
              if (mintAmount && mintAmount.lt(0)) {
                mintAmount = BigNumber.from('0')
              }
              let balanceUsd
              if (balance && info.maxPrice) {
                balanceUsd = balance
                  .mul(info.maxPrice)
                  .div(expandDecimals(1, token.decimals))
              }

              const tokenState = getTokenState(info) || {}
              if (balance !== undefined && info.maxPrice !== undefined) {
                balanceUsd = balance
                  .mul(info.maxPrice)
                  .div(expandDecimals(1, token.decimals))
              }
              return (
                <div
                  key={token.address}
                  className={cx('TokenSelector-token-row border-0 lg:border', {
                    disabled: tokenState.disabled,
                  })}
                  onClick={() => {
                    !tokenState.disabled && onSelectToken(token)
                    close && close()
                  }}
                >
                  {tokenState.disabled && tokenState.message && (
                    <TooltipWithPortal
                      className="TokenSelector-tooltip"
                      handle={<div className="TokenSelector-tooltip-backing" />}
                      position={
                        tokenIndex < filteredTokens.length / 2
                          ? 'center-bottom'
                          : 'center-top'
                      }
                      disableHandleStyle
                      closeOnDoubleClick
                      fitHandleWidth
                      renderContent={() => tokenState.message}
                    />
                  )}

                  <div className="Token-info">
                    {showTokenImgInDropdown && (
                      <RenderTokenIcon symbol={token.symbol} size="largest" />
                    )}
                    <div className="Token-symbol">
                      <div className="font-flexo !text-sm font-medium text-th-fgd-1 sm:!text-base">
                        {token.symbol}
                      </div>
                      <span className="font-flexo !text-sm font-medium text-th-fgd-3">
                        {token.name}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-end gap-1 text-right text-sm">
                    <span className="font-semibold leading-[17.5px]">
                      {(showBalances && balance !== undefined && (
                        <div>
                          {balance.gt(0) &&
                            formatAmount(balance, token.decimals, 4, true)}
                          {balance.isZero() && '-'}
                        </div>
                      )) ||
                        null}
                    </span>

                    <span className="rounded font-normal leading-[17.5px]">
                      {mintAmount && (
                        <div>
                          Mintable:{' '}
                          {formatAmount(mintAmount, token.decimals, 2, true)}{' '}
                          USDG
                        </div>
                      )}
                      {showMintingCap && !mintAmount && <div>-</div>}
                      {!showMintingCap &&
                        showBalances &&
                        balanceUsd !== undefined &&
                        balanceUsd.gt(0) && (
                          <div>${formatAmount(balanceUsd, 30, 2, true)}</div>
                        )}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  function displaySelectedLabel() {
    if (selectedTokenLabel) {
      return (
        <div
          className="flex cursor-pointer items-center gap-1 text-sm font-medium"
          onClick={() => !below.lg && setIsModalVisible(true)}
        >
          {selectedTokenLabel}
          {!showNewCaret && (
            <div className="flex items-center justify-center">
              <ArrowDown />
            </div>
          )}
        </div>
      )
    }

    return (
      <>
        <div className="flex items-center gap-1">
          {showSymbolImage && (
            <RenderTokenIcon
              size="small"
              symbol={tokenInfo?.symbol}
              state={inputFieldValue ? 'active' : 'default'}
            />
          )}
          <span className="text-sm font-medium text-th-fgd-1">
            {tokenInfo?.symbol}
          </span>
        </div>
        {showNewCaret && <DropDownIcon />}
        {!showNewCaret && (
          <div className="ml-2 flex cursor-pointer items-center justify-center">
            <ArrowDown />
          </div>
        )}
      </>
    )
  }

  if (below.lg) {
    return (
      <div
        className={cx({ disabled }, props.className, 'text-left')}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="flex h-full cursor-pointer items-center">
          <Popover className="relative">
            <Popover.Button className="flex items-center justify-center">
              {displaySelectedLabel()}
            </Popover.Button>
            <Popover.Panel className="absolute -right-2 z-10 mt-4 h-[218px] w-[calc(100vw-30px)]">
              {({ close }) => displayTokens(() => close())}
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx({ disabled }, props.className, 'text-left')}
      onClick={(event) => event.stopPropagation()}
    >
      {isModalVisible && displayTokens()}

      <div
        className="flex h-full cursor-pointer items-center"
        onClick={() => setIsModalVisible(true)}
      >
        {displaySelectedLabel()}
      </div>
    </div>
  )
})
