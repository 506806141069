import clsx from 'clsx'
import { ImCheckboxChecked } from 'react-icons/im'

type Props = {
  isChecked?: boolean
  setIsChecked?: (checked: boolean) => void
  disabled?: boolean
  className?: string
  children?: React.ReactNode
  onClick?: () => void
  reverseOrder?: boolean
  size?: 'regular' | 'small'
}

export default function Checkbox({
  isChecked = false,
  setIsChecked,
  disabled = false,
  className,
  onClick,
  reverseOrder = false,
  children,
  size = 'regular',
}: Props) {
  const handleClick = () => {
    if (!disabled && setIsChecked) {
      setIsChecked(!isChecked)
      if (!isChecked) {
        onClick?.()
      }
    }
  }

  const containerClass = clsx(
    'min-w-fit select-none flex min-w-fit select-none items-center justify-between gap-2',
    disabled ? 'pointer-events-none cursor-default' : 'cursor-pointer',
    className,
  )

  const iconClass = clsx(
    'inline-flex items-center text-xl',
    isChecked || disabled ? 'text-th-active' : 'text-th-fgd-2',
  )

  const labelClass = 'text-sm text-th-fgd-3'

  const iconSize = size === 'small' ? 16 : 20

  return (
    <div className={containerClass} onClick={handleClick}>
      {reverseOrder ? (
        <>
          <div className={labelClass}>{children}</div>
          <div className={iconClass}>
            {isChecked ? (
              <ImCheckboxChecked size={iconSize} />
            ) : (
              <div
                style={{
                  width: iconSize,
                  height: iconSize,
                }}
                className="border border-th-fgd-3"
              />
            )}
          </div>
        </>
      ) : (
        <>
          <p className={iconClass}>
            {isChecked ? (
              <ImCheckboxChecked size={iconSize} />
            ) : (
              <div
                style={{
                  width: iconSize,
                  height: iconSize,
                }}
                className="border border-th-fgd-3"
              />
            )}
          </p>
          <p className={labelClass}>{children}</p>
        </>
      )}
    </div>
  )
}
